<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.titulo"
        class="col-12 col-md-9"
        :label="$t('modulos.clausula_contratual.formulario.titulo')"
        obrigatorio
        :max="150"
        trim
      />
      <input-select
        v-model="form.classificacao"
        :options="opcoes.classificacao"
        class="col-12 col-md-3"
        :label="$t('modulos.clausula_contratual.formulario.classificacao')"
        obrigatorio
      />
      <div class="col-12 col-md-12">
        <v-card
          class="v-card--flat px-0 mx-0"
          @contextmenu="show"
        >
          <input-html-editor
            :key="inputHtmlKey"
            v-model="form.descricao"
            desabilitar-align
            desabilitar-media
            class="col-12 col-md-12"
            :label="$t('modulos.clausula_contratual.formulario.descricao') + (mensagemobrigatorio ? '' : ' *')"
            :obrigatorio="mensagemobrigatorio"
          />
        </v-card>
      </div>
      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            class="item-list-barra-superior"
            @click="addTag(item.text)"
          >
            <v-list-item-title
              class="item-barra-superior"
            >
              {{ item.titulo }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ClausulaContratualService from '@common/services/cadastros/ClausulaContratualService';
import TagsContratoClausulaContratualService from '@common/services/cadastros/TagsContratoClausulaContratualService';
import { ClausulaContratualModel } from '@common/models/cadastros/ClausulaContratualModel';
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
import { EnumModel } from '@common/models/geral/EnumModel';
import { EnumTagsClasulaContratual } from '@common/models/geral/EnumTagsClasulaContratual';
import helpers from '@common/utils/helpers';

export default {
  props: ['id', 'contrato'],
  data() {
    return {
      valido: false,
      itemValue: 'seq',
      form: new ClausulaContratualModel({}),
      opcoes: {
        classificacao : [],
        tags: [],
      },
      mensagemobrigatorio: null,
      showMenu: false,
      x: 0,
      y: 0,
      items: [],
      inputHtmlKey: 0
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.clausula_contratual.titulos.editar');
      return this.$t('modulos.clausula_contratual.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.clausula_contratual.validacoes.formulario_invalido');
    },
  },
  watch: {
    'form.descricao': {
      handler(value) {
        let regex = /^<p>\s*|\s*<\/p>$/g;
          let result = value.replace(regex, "");
        if(result != '') {
          this.mensagemobrigatorio = false
        }else{
          this.mensagemobrigatorio = true
          this.valido = false
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    this.buscarClassificacao();
    this.listarTags();
    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'ClausulaContratual', 'Deletar');
    }
    helpers.redirecionarSemPermissao(this, 'ClausulaContratual', 'Inserir');
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ClausulaContratualService.buscar(this.id)
        .then((res) => {
          this.form = new ClausulaContratualModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.clausula_contratual.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.id) {
        this.salvar();
        return
      }
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      if (!this.form.descricao?.includes("<p>")) this.mensagemobrigatorio = true
      if (this.$refs.form.validate() && this.form.descricao?.includes("<p>")) this.valido = true;
      if (!this.valido) return
      if(this.contrato) return this.$router.push({ name: 'contrato-novo', params: { clausula: this.form }});

      this.$store.dispatch('Layout/iniciarCarregamento');
      ClausulaContratualService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'clausula-contratual' });
          this.toastSucesso(this.$t(`modulos.clausula_contratual.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'clausula-contratual' });
      });
    },
    buscarClassificacao: function() {
      EnumeradorService.buscar('EnumClassificacaoClausulaContratual').then((res) => {
        res.sort(helpers.ordenarDropdown)
        this.opcoes.classificacao = new EnumModel(
          res,
          'EnumClassificacaoClausulaContratual'
        );
      });
    },
    show: function (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
    },
    addTag: function(value) {
      const regex = /^<p>\s*|\s*<\/p>$/g;
      this.form.descricao = this.form.descricao ? `<p>${this.form.descricao.replace(regex, '')} ${value}</p>` : `<p>${value}</p>`
    },
    listarTags: function() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TagsContratoClausulaContratualService.listar()
        .then((res) => {
          this.items = new EnumTagsClasulaContratual(res.data)
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
<style>
.item-clausulas-contratuais {
  background-color: red !important;
}
</style>
